import { getTenantOrgDetailsEndpoint } from "../../constants";

const baseUrl = getTenantOrgDetailsEndpoint();

const tenantListUrl = `${baseUrl}/userTenants`;
const productListUrl = `${baseUrl}/userProducts`;
const sendEmailUrl = `${baseUrl}/sendPasswordResetEmail`;
const resetPassword = `${baseUrl}/resetPassword`;
const signUpUrl = `${baseUrl}/userSignup`;
const getAllReportsUrl = `${baseUrl}/getReports`;
const getReportsByIdUrl = `${baseUrl}/getReportById`;
const getPowerBiTokenByUrl = `${baseUrl}/generatePowerbiToken`;
export {
  tenantListUrl,
  productListUrl,
  sendEmailUrl,
  resetPassword,
  signUpUrl,
  getAllReportsUrl,
  getReportsByIdUrl,
  getPowerBiTokenByUrl,
};
