import React from "react";
import { Route, Switch } from "react-router-dom";
import { SignUp } from "../../../LandingPage/SignUp";
import { ForgotPassword } from "../../../ForgotPassword";
import { ConfirmPassword } from "../../../ConfirmPassword";

const NormalRoutes = () => {
  return (
    <Switch>
      {" "}
      <Route exact path={["/signUp"]} component={SignUp} />
      <Route exact path={["/forgotPassword"]} component={ForgotPassword} />
      <Route
        exact
        path="/confirmPassword/:userId/:token"
        component={ConfirmPassword}
      />
    </Switch>
  );
};

export default NormalRoutes;
